import React from 'react';
import Loadable from '@loadable/component';
// Style Imports
import './styles.scss';
// SEO
import Seo from '@components/utility/SEO';
// Structural Imports
import Layout from '@components/structure/Layout.jsx';
// Content Imports
import Hero from '@components/content/hero/Hero.jsx';
import Image from '@components/content/fractals-image.jsx';
import Anchor from "@components/content/anchor.jsx"
import DoctorDiscussionGuideCTA from '@components/content/DoctorDiscussionGuide/DoctorDiscussionGuide.jsx';
import { getLocalizedURL } from '@src/helper'
const Cta = Loadable(() => import('@components/content/cta/CTA'));
const KidneySmallIcon = Loadable(() => import('@components/content/icons/KidneySmall.jsx')
);
const PillIcon = Loadable(() => import('@components/content/icons/Pill.jsx'));
const ShieldIcon = Loadable(() => import('@components/content/icons/Shield.jsx'));
const YogaSmallIcon = Loadable(() => import('@components/content/icons/YogaSmall.jsx'));


const whatIsLupkynis = () => (
    <Layout addedClass='page--what-lupkynis'>
        <Seo
            title='¿Qué es LUPKYNIS&reg; (voclosporina)?'
            description='LUPKYNIS es el primer y único medicamento oral aprobado por la Administración de Alimentos y Medicamentos (Food and Drug Administration, FDA) indicado para el tratamiento de la nefritis lúpica. Vea cómo funciona LUPKYNIS y descargue la guía de conversación con el médico. Consulte la Información de prescripción y seguridad completa y el RECUADRO DE ADVERTENCIA.'
            canonicalURL={'https://www.lupkynis.com' + getLocalizedURL('what-is-lupkynis', 'es')}
        />
        <Hero addedClass='bg-coral hero--string'>
            <div className='col-wrapper'>
                <div className='container'>
                    <div className='hero__content'>
                        <h1 className='heading heading--accent'>¿Qué es LUPKYNIS?</h1>
                        <h2>Un tratamiento <br className='mobile-only' /> que lo ayuda a <span>luchar</span></h2>
                        <p>
                        LUPKYNIS es el primer y único medicamento oral aprobado por la FDA indicado específicamente para el tratamiento de la nefritis lúpica. En ensayos clínicos, se ha demostrado que ayuda a controlar la nefritis lúpica y a proteger los riñones de mayores daños sin dosis altas de esteroides.<sup>a</sup>
                        </p>
                    </div>
                    <div className='hero__image'>
                        <Image
                            data={{
                                desktopSrc: 'what-is-lupkynis/hero-desktop-es.png',
                                mobileSrc: 'what-is-lupkynis/hero-mobile-es.png',
                                altText: 'Suriya, quien comenzó el tratamiento en 2021',
                                loading: 'eager',
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className='icon-bar col-wrapper bg-light-green text-center'>
                <div className='container'>
                    <div className='icon-bar__item'>
                        <div className='icon'>
                            <PillIcon />
                        </div>
                        <div className='icon-bar__content'>
                            <p>
                            Un medicamento <br />
                            oral
                            </p>
                        </div>
                    </div>
                    <div className='icon-bar__item'>
                        <div className='icon'>
                            <YogaSmallIcon />
                        </div>
                        <div className='icon-bar__content'>
                            <p>
                            Ayuda a controlar la nefritis lúpica<sup>b</sup>
                            </p>
                        </div>
                    </div>
                    <div className='icon-bar__item'>
                        <div className='icon'>
                            <KidneySmallIcon />
                        </div>
                        <div className='icon-bar__content'>
                            <p>
                            Ayuda a detener el ataque de <span className='nowrap'>nefritis lúpica</span>
                            </p>
                        </div>
                    </div>
                    <div className='icon-bar__item'>
                        <div className='icon'>
                            <ShieldIcon />
                        </div>
                        <div className='icon-bar__content'>
                            <p>Ayuda a proteger los riñones de daños mayores</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footnote text-center'>
                <p>
                    <sup>a</sup>Como parte de un plan de tratamiento que incluía micofenolato de mofetilo y esteroides.
                </p>
                <p>
                    <sup>b</sup>Se definió el control como la reducción de la proteína en la orina a &le;0.5&nbsp;mg/mg, mientras ayudaba a mantener la función renal.
                </p>
            </div>
            <div className='text-center'>
                <Cta url={getLocalizedURL('why-lupkynis','es')}>
                Obtenga información sobre los resultados de los ensayos clínicos de LUPKYNIS
                </Cta>
            </div>
        </Hero>
        <section id='HowDoesLupkynisWork' className='col-wrapper bg-white'>
            <div className='container'>
                <div className='copy-block text-center'>
                    <div className='how-does-title'>
                        <h4>¿CÓMO ACTÚA LUPKYNIS?</h4>
                    </div>
                    <p>
                    Con la nefritis lúpica, ciertas células del sistema inmunitario se activan cuando normalmente no lo harían y atacan los riñones. LUPKYNIS actúa para contrarrestar ese ataque al calmar el sistema inmunitario. Ayuda a reducir la inflamación y proteger los riñones de un mayor daño permanente sin el uso de dosis altas de esteroides. 
                    </p>
                </div>
                <Image
                    data={{
                        desktopSrc: 'what-is-lupkynis/improvement-desktop-es.png',
                        mobileSrc: 'what-is-lupkynis/improvement-mobile-es.png',
                        altText: '“Después de que comencé a tomar LUPKYNIS, los resultados de mi primera serie de pruebas, incluidos análisis de sangre y uroanálisis, mostraron mejoría”. - Jennifer, quien comenzó el tratamiento en el 2021',
                        loading: 'eager',
                    }}
                />
            </div>
        </section>

        <section id='OneTwoPunch' className='bg-white text-center'>
            <div className='container'>
                <div className='icon-callout'>
                    <div className='icon desktop-only'></div>
                    <div className='copy-block'>
                        <h3 className='heading heading--subhead'>LUPKYNIS es un potente arma de doble efecto <span className='yellow-bg'>arma de doble efecto</span></h3>
                    </div>
                </div>
                <div className='col-wrapper'>
                    <div className='container'>
                        <div className='lupkynis-one-two-punch'>
                            <div className='icon-callout icon-callout--strong'>
                                <div className='icon'>
                                    <Image
                                        data={{
                                            desktopSrc: 'what-is-lupkynis/uno.png',
                                            altText: 'Uno'
                                        }}
                                        width="123px"
                                    />
                                </div>
                                <div>
                                    <p>
                                        <span className='sr-only'>Uno:</span> Ayuda a detener el ataque a los riñones
                                    </p>
                                </div>
                            </div>
                            <div>
                                <p>
                                LUPKYNIS ayuda a calmar el sistema inmunitario, reduciendo la inflamación en los riñones y ayudando a detener el ataque de la nefritis lúpica.
                                </p>
                            </div>
                        </div>
                        <div className='lupkynis-one-two-punch'>
                            <div className='icon-callout icon-callout--strong'>
                                <div className='icon'>
                                    <Image
                                        data={{
                                            desktopSrc: 'what-is-lupkynis/dos.png',
                                            altText: 'Dos'
                                        }}
                                        width="120px"
                                    />
                                </div>
                                <div>
                                    <p>
                                        <span className='sr-only'>Dos:</span> Ayuda a proteger los riñones de daños futuros
                                    </p>
                                </div>
                            </div>
                            <div>
                                <p>
                                LUPKYNIS protege las células específicas de los riñones para ayudar a mantenerlos en funcionamiento. Actúa ayudando a evitar que las proteínas se filtren en la orina, lo cual es un signo clave de actividad de la enfermedad. 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <Cta url={getLocalizedURL('why-lupkynis','es')}>Vea cómo funciona</Cta>
            </div>
        </section>
        <DoctorDiscussionGuideCTA />
        <section id="PeerConnect" className="col-wrapper bg-white">
            <div className="container">
                <div className="copy-block">
                    <h3 className="heading heading--subhead">Comuníquese con alguien que ha pasado por lo mismo</h3>
                    <p><strong>Lupus Nephritis Peer Connect&trade;</strong> es un programa de tutoría que le permite hablar con alguien que pueda comprender lo que está atravesando. Nuestros mentores se ofrecen como voluntarios para realizar llamadas individuales y responder a sus preguntas, animarlo y compartir sus experiencias personales con la nefritis lúpica y el tratamiento con LUPKYNIS.</p>
                    <div>
                    <Anchor url="https://www.lnpeerconnect.com/" target="_blank" linkClass="button">Más información</Anchor>
                    </div>
                </div>
            </div>
        </section>
    </Layout>
);

export default whatIsLupkynis;
